import * as types from '../mutation-types'

export const fetchSocialMediaPages = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchSocialMediaPages')

  return new Promise((resolve, reject) => {
    model
      .fetchSocialMediaPages(options)
      .then((response) => {
        dispatch(
          'socialMediaPages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchSocialMediaPages')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSocialMediaPages,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveSocialMediaPage = (
  { dispatch, commit },
  { model, socialMediaPage }
) => {
  commit(types.API_REQUEST_START, 'createSocialMediaPage')

  return new Promise((resolve, reject) => {
    model
      .saveSocialMediaPage(socialMediaPage)
      .then((response) => {
        dispatch(
          'socialMediaPages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'saveSocialMediaPage')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveSocialMediaPage,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              socialMediaPage
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroySocialMediaPage = (
  { dispatch, commit },
  { model, socialMediaPageId }
) => {
  commit(types.API_REQUEST_START, 'destroySocialMediaPage')

  return new Promise((resolve, reject) => {
    model
      .destroySocialMediaPage(socialMediaPageId)
      .then((response) => {
        dispatch(
          'socialMediaPages/destroyResourcesFromRelationships',
          socialMediaPageId,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'destroySocialMediaPage')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroySocialMediaPage,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              socialMediaPageId
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
