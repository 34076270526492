import * as types from '../mutation-types'

export const fetchSocialMediaConversations = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchSocialMediaConversations')

  return new Promise((resolve, reject) => {
    model
      .fetchSocialMediaConversations(options)
      .then((response) => {
        dispatch(
          'socialMediaConversations/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'socialMediaMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'socialMediaMessages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'socialMediaPages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchSocialMediaConversations')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSocialMediaConversations,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
